import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import {
  PageGridBlock as IPageGridBlock,
  PageSummary,
} from "../../@types/graphql.generated";
import { PageGrid, Promo, VideoPlayer } from "@ui-components";
import { Block } from "../Block/Block";
import { qBankImageSrc, qBankVideoSrc, FallbackImage } from "../../utils";
import { BlockCommonLayout, BlockTitlePreamble } from "../../utils/layout";

export interface PageGridBlockProps extends IPageGridBlock {
  index: number;
  isFirstImageBlock: boolean;
}

const PageGridBlock: React.FC<PageGridBlockProps> = (props) => (
  <Block
    themeColorVariant={props.themeColorVariant || undefined}
    theme={props.theme || undefined}
  >
    <BlockCommonLayout>
      {props.title && (
        <BlockTitlePreamble
          title={props.title}
          preamble={props.preamble || undefined}
        />
      )}
      <PageGrid mirrorLayout={props.mirrorLayout || undefined}>
        {props?.pages?.map((page: PageSummary | null, i) => {
          const pageGridOrder = i + 1;
          const hideMedia = props?.pages?.length === 4 && pageGridOrder > 2;
          const sizes =
            (!props.mirrorLayout && i === 0) || (props.mirrorLayout && i === 2)
              ? "(max-width: 466px) 828px, 1080px"
              : "(min-width: 673px) and (max-width: 830px) 750px, (min-width: 831px) and (max-width: 967px) 828px, 640px";

          if (page?.url) {
            return (
              <Link
                href={page.url}
                style={{ width: "100%" }}
                key={i + "-" + page?.url}
                prefetch={false}
              >
                <Promo
                  media={
                    !hideMedia ? (
                      page.videoUrl ? (
                        <VideoPlayer
                          useCustomControls={true}
                          autoplay={true}
                          url={qBankVideoSrc(page.videoUrl)}
                          muted
                          playsinline
                          loop
                        />
                      ) : page.image && page.image.src ? (
                        <Image
                          alt=""
                          src={qBankImageSrc(page.image.src, "1600")}
                          width="1600"
                          height="900"
                          sizes={sizes}
                          priority={props.isFirstImageBlock}
                        />
                      ) : (
                        <FallbackImage />
                      )
                    ) : null
                  }
                  pageGridOrder={pageGridOrder}
                  bgColor={page?.theme?.primaryColor || undefined}
                  textColor={page?.theme?.textColor || undefined}
                  {...page}
                  title={page.title || undefined}
                  preamble={page.preamble || undefined}
                />
              </Link>
            );
          }
        })}
      </PageGrid>
    </BlockCommonLayout>
  </Block>
);

export { PageGridBlock };
