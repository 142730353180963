import * as React from "react";
import { Flex, INNER_CONTAINER_MAX_WIDTH, VideoPlayer } from "@ui-components";
import { Block } from "../Block/Block";
import { VideoBlock as IVideoBlock } from "../../@types/graphql.generated";
import { BlockFullWidthLayout, BlockTitlePreamble } from "../../utils/layout";
import { trackVideoInteraction } from "../../utils";

const VideoBlock: React.FC<IVideoBlock> = (props) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [progressEventSent, setProgressEventSent] = React.useState(false);

  return (
    <Block
      themeColorVariant={props.themeColorVariant || undefined}
      theme={props.theme || undefined}
    >
      <BlockFullWidthLayout>
        <Flex
          px={{ _: 0, l: 10 }}
          w={{ _: "100%" }}
          maxw={INNER_CONTAINER_MAX_WIDTH}
          alignItems="center"
          flexDirection="column"
        >
          <BlockTitlePreamble
            title={props.title || ""}
            preamble={props.preamble || undefined}
          />
          <VideoPlayer
            url={props.url || undefined}
            loadWhenInView={true}
            onStart={() => {
              trackVideoInteraction("Play", window?.location?.pathname);
              setIsPlaying(true);
            }}
            onProgress={({ played }) => {
              if (
                isPlaying &&
                !progressEventSent &&
                parseFloat(played.toFixed(1)) === 0.5
              ) {
                trackVideoInteraction("50% progress", window?.location?.pathname);
                setProgressEventSent(true);
              }
            }}
            onEnded={() => {
              trackVideoInteraction("Complete", window?.location?.pathname);
              setIsPlaying(false);
            }}
          />
        </Flex>
      </BlockFullWidthLayout>
    </Block>
  );
};

export { VideoBlock };
