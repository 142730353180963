"use client"
import * as React from "react";
import { Text, Flex, ShowMore, Rte } from "@ui-components";
import {
  ShowIntroBlock as IShowIntroBlock,
  BlockType,
} from "../../@types/graphql.generated";
import { trackShowMoreToggle } from "./utils";
import { usePathname } from "next/navigation";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import createMarkup from "@/utils/createMarkup";

const ShowIntroBlock: React.FC<IShowIntroBlock> = (props) => {
  const pathname = usePathname();
  const locales = useLocales(
    translationNamespace.block(BlockType.ShowIntroBlock),
    ["readMoreAria"]
  );

  return (
    <Flex
      pt={{ _: 3, l: 16, xl: 0 }}
      pb={{ _: 8, l: 8 }}
      px={{ _: 2, s: 5, l: 10, xxl: 20 }}
      minh={{ _: "auto", xl: "var(--showHeroxHeight)" }}
    >
      <Flex flex={{ xl: 6 / 12 }}></Flex>
      <Flex
        w={1}
        maxw={{ _: 200, xxl: 240 }}
        justifyContent={{ _: "center", xl: "start" }}
        flex={{ xl: 6 / 12 }}
      >
        <Flex flexDirection="column" pt={{ _: 4, l: 8 }} w={1} maxw={{ l: 85 }}>
          <ShowMore
            label={props.showMoreLabel || ""}
            ariaLabel={locales.readMoreAria}
            maxHeight={26.25} // Fixed value for now
            onExpand={() => trackShowMoreToggle(pathname)}
          >
            <Text fontSize={{ _: 2.25, m: 3 }} lineHeight={{ _: 3.125, m: 4 }}>
              {props.preamble}
            </Text>
            <Rte>
              {props?.rte?.text && (
                <div dangerouslySetInnerHTML={createMarkup(props.rte.text)} />
              )}
            </Rte>
          </ShowMore>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { ShowIntroBlock };
