"use client";
import React, { useEffect } from "react";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import { useInView } from "react-intersection-observer";
import { ColorThemeContext } from "../../context/ColorThemeContext";
import { ThemeColorVariant, Theme } from "../../@types/graphql.generated";
import { Flex, lightTheme, mergeColorTheme } from "@ui-components";
import { ColorTheme } from "../ui-components/src/@types/styled";

interface BlockProps {
  themeColorVariant?: ThemeColorVariant;
  theme?: Theme;
  children?: React.ReactNode;
}

export const Block: React.FC<BlockProps> = (props) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  const { setThemeColorBackground } = React.useContext(ColorThemeContext);

  const setThemeColorBackgroundRef = React.useRef(() =>
    props.themeColorVariant
      ? setThemeColorBackground(inView, props.themeColorVariant)
      : undefined
  );

  useEffect(() => {
    setThemeColorBackgroundRef.current();
  }, [inView]);

  return (
    <div ref={props.themeColorVariant ? ref : null}>
      {/* use page or block theming based on themeColorVariant value */}
      {props.themeColorVariant ? (
        props.children
      ) : (
        <ThemeProvider
          theme={mergeColorTheme(
            (props.theme as ColorTheme) || (lightTheme as ColorTheme)
          )}
        >
          <Flex
            display="block"
            style={{
              backgroundColor:
                props.theme && props.theme.primaryColor
                  ? props.theme.primaryColor
                  : "transparent",
            }}
          >
            {props.children}
          </Flex>
        </ThemeProvider>
      )}
    </div>
  );
};
