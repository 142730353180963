import * as React from "react";
import Image from "next/image";
import {
  ProfilePageHeroBlock,
  ParticipantsBlock,
} from "../../@types/graphql.generated";
import { ProfileCard, Text } from "@ui-components";
import { qBankImageSrc } from "../../utils/assetPaths";
import { profileFallbackImageSrc } from "../../utils/fallbackImage";

export interface ParticipantsTab {
  id: string;
  label: string;
  participants: ProfilePageHeroBlock[];
  rte: string;
}

export const mapParticipantListsToTabs = (
  props: ParticipantsBlock,
  locales: Record<"crewLabel" | "castLabel", string>
): {
  id: string;
  label: string;
  participants?: ProfilePageHeroBlock[];
  rte?: string;
}[] => {
  let tabs = [];

  if (
    (props.castParticipants && props.castParticipants?.length > 0) ||
    props.castListing
  ) {
    tabs.push({
      id: "cast",
      label: locales.castLabel,
      participants:
        props.castParticipants && props.castParticipants.length > 0
          ? (props.castParticipants.filter(Boolean) as ProfilePageHeroBlock[]) // Ensure no null values
          : [],
      rte: props.castListing ?? undefined,
    });
  }

  if (
    (props.crewParticipants && props.crewParticipants.length > 0) ||
    props.crewListing
  ) {
    tabs.push({
      id: "crew",
      label: locales.crewLabel,
      participants: [],
      rte: props.crewListing ?? undefined,
    });
  }

  return tabs;
};

export const Participant: React.FC<ProfilePageHeroBlock> = (props) => (
  <ProfileCard
    image={
      <Image
        src={
          props.image && props.image.src
            ? qBankImageSrc(props.image.src, "600")
            : profileFallbackImageSrc
        }
        alt=""
        width="600"
        height="600"
        sizes="(min-width: 769px) and (max-width: 967px) 210px, 110px"
      />
    }
    alignItems="center"
    textAlign="center"
    variant="asParticipant"
  >
    {props.title && (
      <Text
        as="span"
        display={"block"}
        fontSize={{ _: 1.75, s: 1.5 }}
        lineHeight={2}
        mb={0.75}
        textTransform={"uppercase"}
      >
        {props.title}
      </Text>
    )}
    {props.url ? (
      <Text.Link
        href={props.url.url}
        fontSize={{ _: 2, l: 1.75 }}
        lineHeight={{ _: 2.5, l: 2.25 }}
        fontWeight="semibold"
        style={{ textDecoration: "underline" }}
        prefetch={false}
      >
        {props.name}
      </Text.Link>
    ) : (
      <Text
        fontSize={{ _: 2, l: 1.75 }}
        lineHeight={{ _: 2.5, l: 2.25 }}
        fontWeight="semibold"
      >
        {props.name}
      </Text>
    )}
  </ProfileCard>
);
