import * as React from "react";
import {
  Flex,
  Text,
  INNER_MAX_WIDTH,
  READABLE_LINE_MAX_WIDTH,
} from "@ui-components";
import { Block } from "../Block/Block";
import { IFrameBlock as IIFrameBlock } from "../../@types/graphql.generated";

const IFrameBlock: React.FC<IIFrameBlock> = (props) => {
  return (
    <Block
      themeColorVariant={props.themeColorVariant || undefined}
      theme={props.theme || undefined}
    >
      <Flex py={{ _: 8, l: 8 }} px={{ _: 0, l: 10 }}>
        <Flex
          alignItems="center"
          flexDirection="column"
          w={1}
          maxw={INNER_MAX_WIDTH}
          style={{ margin: "0 auto" }}
        >
          {props.title && (
            <Flex
              maxw={READABLE_LINE_MAX_WIDTH}
              flexDirection="column"
              alignItems="center"
              style={{ marginLeft: "auto", marginRight: "auto" }}
              mb={{ _: 3, m: 8 }}
            >
              {props.title && (
                <Text
                  variant="headline-2"
                  mb={{ _: 2, m: 5 }}
                  textAlign="center"
                >
                  {props.title}
                </Text>
              )}
              {props.preamble && (
                <Text variant="headline-4" as="p" textAlign="center">
                  {props.preamble}
                </Text>
              )}
            </Flex>
          )}
          <Flex
            flexDirection="column"
            w={1}
            h={props.height ? props.height / 8 : 80}
          >
            <iframe
              title={props.title || ""}
              src={props.src}
              frameBorder="0"
              height="100%"
              width="100%"
            ></iframe>
          </Flex>
        </Flex>
      </Flex>
    </Block>
  );
};

export { IFrameBlock };
