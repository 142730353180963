"use client";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { usePathname, useRouter } from "next/navigation";

import {
  GetPageQueryResult,
} from "./operations.generated";

// components
import { Flex } from "@ui-components";
import { ThemeColorBackground } from "../ThemeColorBackground";
import { BlockResolver } from "../BlockResolver";

// types
import * as Types from "../../@types/graphql.generated";

// utils
import {
  loadCookieControl,
  getCookieConsentByCategory,
} from "../../utils/cookieControl";
import { sendMatomoEvent } from "../../utils";

// hooks
import { useAnchorLinkScrolling } from "../../hooks";

interface BasePageSSRProps {
  data: GetPageQueryResult["data"];
}

export const BasePageSSR: React.FC<BasePageSSRProps> = ({
  data
}) => {
  const pathname = usePathname();

  useAnchorLinkScrolling();

  React.useEffect(() => {
    loadCookieControl();
  }, []);

  // Send event to Matomo Tag Manager on every page load to get consent on analytical and marketing cookies
  React.useEffect(() => {
    const handleCookieControlLoad = () => {
      const hasAnalyticsCookiesConsent = getCookieConsentByCategory('analytics');
      const hasMarketingCookiesConsent = getCookieConsentByCategory('marketing');

      sendMatomoEvent({
        event: 'analyticsConsent',
        analyticsConsentState: !!hasAnalyticsCookiesConsent,
      });

      sendMatomoEvent({
        event: 'marketingConsent',
        marketingConsentState: !!hasMarketingCookiesConsent,
      });
    };

    if (typeof window !== 'undefined' && window.CookieControl) {
      if (window.CookieControl.isLoaded) {
        handleCookieControlLoad();
      } else {
        window.addEventListener('CookieControl.load', handleCookieControlLoad);
      }
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (typeof window !== 'undefined' && window.CookieControl) {
        window.removeEventListener('CookieControl.load', handleCookieControlLoad);
      }
    };
  }, [pathname]);

  return (
    <main id="main" key={data?.page?.id}>
      <Flex>
        <Flex
          display="block"
          w={1}
          style={{
            position: "relative",
          }}
        >
          {data?.page?.blocks && (
            <BlockResolver
              blocks={data.page.blocks as Array<Types.Blocks>}
            />
          )}
          <ThemeColorBackground />
        </Flex>
      </Flex>
    </main>
  );
};
