"use client";
import * as React from "react";
import {
  ShowHero,
  ResponsiveImage,
  InfoBox,
  Accordion,
  Text,
  Flex,
  VideoPlayer,
  Icon,
  IconTypes,
  scale,
} from "@ui-components";
import {
  imageDataToImageProps,
  qBankVideoSrc,
  FallbackImage,
} from "../../utils";
import { ThemeContext } from "styled-components";
import {
  CtaUnionGraphType,
  ShowHeroBlock as IShowHeroBlock,
  BlockType,
} from "../../@types/graphql.generated";
import { trackTicketLinkClickEvent } from "../Menu/utils";
import { trackInfoBoxAccordionExpand } from "./utils";
import { usePathname } from "next/navigation";
import { useLocales, translationNamespace } from "../../hooks/useLocales";

export interface ShowHeroBlockProps extends IShowHeroBlock {
  ctaButton: CtaUnionGraphType;
}

const ShowHeroBlock: React.FC<ShowHeroBlockProps> = (props) => {
  const themeContext = React.useContext(ThemeContext);
  const heroContentRef = React.useRef() as React.MutableRefObject<any>;
  const heroMediaRef = React.useRef() as React.MutableRefObject<any>;
  const infoBoxRef = React.useRef() as React.MutableRefObject<any>;
  const pathname = usePathname();
  const miscLocales = useLocales(translationNamespace.common("Misc"), [
    "opensInNewTab",
  ]);
  const calendarLocales = useLocales(
    translationNamespace.block(BlockType.SeasonCalendarBlock),
    ["anchorLink"]
  );
  const locales = { ...miscLocales, ...calendarLocales };
  const heroImageProps =
    props.image && props.image.src
      ? imageDataToImageProps(props.image)
      : undefined;

  React.useEffect(() => {
    if (!heroContentRef.current) {
      return;
    }

    const handleResize = () => {
      const padding = 64;
      let root = document.documentElement;
      const offset = heroContentRef.current.offsetTop;
      const height = heroContentRef.current.clientHeight + padding - offset;
      root.style.setProperty("--showHeroxMinHeight", height + "px");
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heroContentRef]);

  React.useEffect(() => {
    if (!infoBoxRef.current) {
      return;
    }
    const handleResize = () => {
      const padding = scale.px(8);
      let root = document.documentElement;
      const top = window
        .getComputedStyle(heroContentRef.current)
        .getPropertyValue("top");
      const infoboxHeight =
        infoBoxRef.current.clientHeight + infoBoxRef.current.offsetTop;
      const heroMediaHeight =
        heroMediaRef.current.clientHeight + heroMediaRef.current.offsetTop;
      const showHeroHeight = `calc(${
        infoboxHeight - heroMediaHeight
      }px + ${top} + ${padding})`;
      root.style.setProperty("--showHeroxHeight", showHeroHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [infoBoxRef, heroMediaRef, heroContentRef]);

  return (
    <Flex
      display="block"
      style={{
        backgroundColor: themeContext?.primaryColor || "",
      }}
    >
      <ShowHero
        mediaRef={heroMediaRef}
        contentRef={heroContentRef}
        locales={{
          tag: props.tag || "",
          name: props.name || "",
          title: props.title || "",
          riderAndCreator: props.riderAndCreator || "",
        }}
        media={
          heroImageProps || props.videoUrl ? (
            props.videoUrl ? (
              <VideoPlayer
                useCustomControls={true}
                autoplay={true}
                url={qBankVideoSrc(props.videoUrl)}
                muted
                playsinline
                loop
              />
            ) : heroImageProps ? (
              <ResponsiveImage {...heroImageProps} />
            ) : null
          ) : (
            <FallbackImage />
          )
        }
      >
        <InfoBox innerRef={infoBoxRef}>
          <InfoBox.List>
            {props?.info?.map((c) => {
              return c?.asAccordion && themeContext ? (
                <InfoBox.Item key={c.label}>
                  <Accordion>
                    <Accordion.Header
                      id={c.label || ""}
                      toggleLabel={c.label || undefined}
                      onExpand={() => trackInfoBoxAccordionExpand(pathname)}
                    >
                      <Text
                        variant="paragraph-1"
                        fontSize={{ _: 2.25, m: 2.5 }}
                        textColor={themeContext.dark ? "black-light" : "white"}
                      >
                        {c.label}
                      </Text>
                    </Accordion.Header>
                    <Accordion.Panel id={c.label || ""}>
                      <Text
                        fontSize={2}
                        lineHeight={3}
                        textColor={themeContext.dark ? "black-light" : "white"}
                        pb={c.link ? 2 : 0}
                      >
                        {c.text}
                      </Text>

                      {c.link &&
                        (c.link.url.indexOf("http") > -1 ? (
                          <Text.Anchor
                            fontSize={2}
                            lineHeight={3}
                            textColor={
                              themeContext.dark ? "black-light" : "white"
                            }
                            href={c.link.url}
                            target={`_${c.link.target}`}
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            {c.link.title}
                          </Text.Anchor>
                        ) : (
                          <Text.Link
                            fontSize={2}
                            lineHeight={3}
                            textColor={
                              themeContext.dark ? "black-light" : "white"
                            }
                            href={c.link.url}
                            style={{ textDecoration: "underline" }}
                            prefetch={false}
                          >
                            {c.link.title}
                          </Text.Link>
                        ))}
                    </Accordion.Panel>
                  </Accordion>
                </InfoBox.Item>
              ) : themeContext ? (
                <InfoBox.Item key={c?.label}>
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <Text
                      variant="paragraph-1"
                      fontSize={{ _: 2.25, m: 2.5 }}
                      mr={2}
                      textColor={themeContext.dark ? "black-light" : "white"}
                    >
                      {c?.label}
                    </Text>
                    {c?.anchorLinkId ? (
                      /* Calendar block anchor link */
                      <Text.Anchor
                        href={`#${c.anchorLinkId}`}
                        variant="paragraph-1"
                        fontWeight="light"
                        fontSize={{ _: 2.25, m: 2.5 }}
                        textColor={themeContext.dark ? "black-light" : "white"}
                        ml={c.icon ? "auto" : 0}
                        mb={0}
                        style={{
                          textDecoration: "underline",
                          textDecorationThickness: "1px",
                          fontVariantNumeric: "lining-nums",
                        }}
                      >
                        {c.text}
                      </Text.Anchor>
                    ) : (
                      /* Plain text info */
                      <Text
                        variant="paragraph-1"
                        fontWeight="light"
                        fontSize={{ _: 2.25, m: 2.5 }}
                        textColor={themeContext.dark ? "black-light" : "white"}
                        ml={c?.icon ? "auto" : 0}
                      >
                        {c?.text}
                      </Text>
                    )}
                    {c?.icon && c.anchorLinkId && (
                      /* Round anchor link btn for Calendar */
                      <a
                        href={`#${c.anchorLinkId}`}
                        aria-label={locales.anchorLink ?? ""}
                      >
                        <InfoBox.ItemIcon>
                          <Icon
                            icon={c.icon.toLowerCase() as IconTypes}
                            color={themeContext.dark ? "black-light" : "white"}
                            size={{ _: 2.5, m: 3 }}
                          />
                        </InfoBox.ItemIcon>
                      </a>
                    )}
                  </Flex>
                </InfoBox.Item>
              ) : null;
            })}
          </InfoBox.List>
          {props.ctaButton ? (
            props.ctaButton.__typename === "link" ? (
              props.ctaButton.url.indexOf("http") > -1 ? (
                <InfoBox.CtaExternalLink
                  href={props.ctaButton.url}
                  target={`_${props.ctaButton.target}`}
                  rel="noopener noreferrer"
                  label={props.ctaButton.title}
                  aria-label={`${props.ctaButton.title} (${locales.opensInNewTab})`}
                  onClick={() =>
                    props.ctaButton.__typename === "link"
                      ? trackTicketLinkClickEvent(
                          props.ctaButton.title,
                          pathname,
                          "Show page hero"
                        )
                      : null
                  }
                />
              ) : (
                <InfoBox.CtaLink
                  href={props.ctaButton.url}
                  label={props.ctaButton.title}
                  onClick={() =>
                    props.ctaButton.__typename === "link"
                      ? trackTicketLinkClickEvent(
                          props.ctaButton.title,
                          pathname,
                          "Show page hero"
                        )
                      : null
                  }
                />
              )
            ) : props.ctaButton.__typename === "stringBox" && themeContext ? (
              <Flex
                p={{ _: 2, m: 3 }}
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  textColor={themeContext.dark ? "black" : "white"}
                  fontWeight="semibold"
                  mb={0}
                >
                  {props.ctaButton.value}
                </Text>
              </Flex>
            ) : null
          ) : null}
        </InfoBox>
      </ShowHero>
    </Flex>
  );
};

export { ShowHeroBlock };
