"use client";
import { Theme } from "@/@types/graphql.generated";
import { globalStyles } from "@/components/ui-components/src";
import {
  ColorThemeProvider,
  UIContextProvider,
  LocalesContextProvider,
  LocalesContextProviderProps,
  SnackbarContextProvider,
} from "@/context";

const GlobalStyles = globalStyles();

export interface AppContextProviderProps {
  localesContextProviderProps: LocalesContextProviderProps;
  defaultTheme?: Theme;
  children: React.ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = (
  props
) => {
  return (
    <LocalesContextProvider {...props.localesContextProviderProps}>
      <ColorThemeProvider defaultTheme={props.defaultTheme}> 
        <UIContextProvider>
          <SnackbarContextProvider>
            <GlobalStyles />
            {props.children}
          </SnackbarContextProvider>
        </UIContextProvider>
      </ColorThemeProvider>
    </LocalesContextProvider>
  );
};
