import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CommonSearchResultsLinksFragment = (
  { __typename?: 'link' }
  & Pick<Types.Link, 'alt' | 'target' | 'title' | 'url'>
);

export type GetCommonSearchResultsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCommonSearchResultsQuery = (
  { __typename?: 'query' }
  & { getCommonSearchResults?: Types.Maybe<(
    { __typename?: 'commonSearchResult' }
    & Pick<Types.CommonSearchResult, 'title'>
    & { results?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'link' }
      & CommonSearchResultsLinksFragment
    )>>> }
  )> }
);

export const CommonSearchResultsLinksFragmentDoc = gql`
    fragment commonSearchResultsLinks on link {
  alt
  target
  title
  url
}
    `;
export const GetCommonSearchResultsDocument = gql`
    query GetCommonSearchResults {
  getCommonSearchResults {
    title
    results {
      ... on link {
        ...commonSearchResultsLinks
      }
    }
  }
}
    ${CommonSearchResultsLinksFragmentDoc}`;

/**
 * __useGetCommonSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetCommonSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonSearchResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommonSearchResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetCommonSearchResultsQuery, GetCommonSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommonSearchResultsQuery, GetCommonSearchResultsQueryVariables>(GetCommonSearchResultsDocument, options);
      }
export function useGetCommonSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommonSearchResultsQuery, GetCommonSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommonSearchResultsQuery, GetCommonSearchResultsQueryVariables>(GetCommonSearchResultsDocument, options);
        }
export type GetCommonSearchResultsQueryHookResult = ReturnType<typeof useGetCommonSearchResultsQuery>;
export type GetCommonSearchResultsLazyQueryHookResult = ReturnType<typeof useGetCommonSearchResultsLazyQuery>;
export type GetCommonSearchResultsQueryResult = Apollo.QueryResult<GetCommonSearchResultsQuery, GetCommonSearchResultsQueryVariables>;