import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LanguageSwitchQueryVariables = Types.Exact<{
  path: Types.Scalars['String'];
  culture: Types.Scalars['String'];
}>;


export type LanguageSwitchQuery = (
  { __typename?: 'query' }
  & { languageSwitch?: Types.Maybe<(
    { __typename?: 'languageSwitchResult' }
    & Pick<Types.LanguageSwitchResult, 'redirectUrlEn' | 'redirectUrlSv'>
  )> }
);


export const LanguageSwitchDocument = gql`
    query LanguageSwitch($path: String!, $culture: String!) {
  languageSwitch(path: $path, culture: $culture) {
    redirectUrlEn
    redirectUrlSv
  }
}
    `;

/**
 * __useLanguageSwitchQuery__
 *
 * To run a query within a React component, call `useLanguageSwitchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageSwitchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageSwitchQuery({
 *   variables: {
 *      path: // value for 'path'
 *      culture: // value for 'culture'
 *   },
 * });
 */
export function useLanguageSwitchQuery(baseOptions: Apollo.QueryHookOptions<LanguageSwitchQuery, LanguageSwitchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguageSwitchQuery, LanguageSwitchQueryVariables>(LanguageSwitchDocument, options);
      }
export function useLanguageSwitchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguageSwitchQuery, LanguageSwitchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguageSwitchQuery, LanguageSwitchQueryVariables>(LanguageSwitchDocument, options);
        }
export type LanguageSwitchQueryHookResult = ReturnType<typeof useLanguageSwitchQuery>;
export type LanguageSwitchLazyQueryHookResult = ReturnType<typeof useLanguageSwitchLazyQuery>;
export type LanguageSwitchQueryResult = Apollo.QueryResult<LanguageSwitchQuery, LanguageSwitchQueryVariables>;