import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SearchBlockFieldsFragment = (
  { __typename?: 'searchBlock' }
  & Pick<Types.SearchBlock, 'id' | 'type' | 'themeColorVariant' | 'title'>
  & { archiveLink?: Types.Maybe<(
    { __typename?: 'CtaGraphType' }
    & Pick<Types.CtaGraphType, 'label' | 'url'>
  )> }
);

export type CommonSearchResultItemFieldsFragment = (
  { __typename?: 'commonSearchResultItem' }
  & Pick<Types.CommonSearchResultItem, 'id' | 'tag' | 'preamble' | 'email' | 'phone' | 'role' | 'type' | 'passed'>
  & { dateRange?: Types.Maybe<(
    { __typename?: 'dateRange' }
    & { from?: Types.Maybe<(
      { __typename?: 'dateTime' }
      & Pick<Types.DateTime, 'datetime'>
      & { formatted?: Types.Maybe<(
        { __typename?: 'formattedDate' }
        & Pick<Types.FormattedDate, 'date' | 'time' | 'weekday' | 'year'>
      )> }
    )>, to?: Types.Maybe<(
      { __typename?: 'dateTime' }
      & Pick<Types.DateTime, 'datetime'>
      & { formatted?: Types.Maybe<(
        { __typename?: 'formattedDate' }
        & Pick<Types.FormattedDate, 'date' | 'time' | 'weekday' | 'year'>
      )> }
    )> }
  )>, title?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'url' | 'title'>
  )>, image?: Types.Maybe<(
    { __typename?: 'image' }
    & Pick<Types.Image, 'id' | 'alt' | 'placeholder' | 'src'>
  )>, theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'primaryColor' | 'complementaryColor' | 'textColor' | 'dark'>
  )>, ticketUrl?: Types.Maybe<(
    { __typename: 'link' }
    & CtaLinkFragment
  ) | (
    { __typename: 'stringBox' }
    & StringValueFragment
  )> }
);

export type CtaLinkFragment = (
  { __typename?: 'link' }
  & Pick<Types.Link, 'title' | 'url' | 'alt' | 'target'>
);

export type StringValueFragment = (
  { __typename?: 'stringBox' }
  & Pick<Types.StringBox, 'value'>
);

export type SearchQueryVariables = Types.Exact<{
  searchInput: Types.Scalars['String'];
  pageInput: Types.PaginationInput;
}>;


export type SearchQuery = (
  { __typename?: 'query' }
  & { search?: Types.Maybe<(
    { __typename?: 'search' }
    & Pick<Types.Search, 'searchInput'>
    & { pagination: (
      { __typename?: 'pagination' }
      & Pick<Types.Pagination, 'page' | 'pageSize' | 'totalPages' | 'totalSize'>
    ), results?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'commonSearchResultItem' }
      & CommonSearchResultItemFieldsFragment
    )>>> }
  )> }
);

export const SearchBlockFieldsFragmentDoc = gql`
    fragment searchBlockFields on searchBlock {
  id
  type
  themeColorVariant
  title
  archiveLink {
    label
    url
  }
}
    `;
export const CtaLinkFragmentDoc = gql`
    fragment CTALink on link {
  title
  url
  alt
  target
}
    `;
export const StringValueFragmentDoc = gql`
    fragment stringValue on stringBox {
  value
}
    `;
export const CommonSearchResultItemFieldsFragmentDoc = gql`
    fragment commonSearchResultItemFields on commonSearchResultItem {
  id
  dateRange {
    from {
      datetime
      formatted {
        date
        time
        weekday
        year
      }
    }
    to {
      datetime
      formatted {
        date
        time
        weekday
        year
      }
    }
  }
  tag
  title {
    alt
    target
    url
    title
  }
  preamble
  image {
    id
    alt
    placeholder
    src
  }
  theme {
    primaryColor
    complementaryColor
    textColor
    dark
  }
  email
  phone
  role
  type
  ticketUrl {
    __typename
    ... on link {
      ...CTALink
    }
    ... on stringBox {
      ...stringValue
    }
  }
  passed
}
    ${CtaLinkFragmentDoc}
${StringValueFragmentDoc}`;
export const SearchDocument = gql`
    query Search($searchInput: String!, $pageInput: paginationInput!) {
  search(searchInput: $searchInput, pageInput: $pageInput) {
    searchInput
    pagination {
      page
      pageSize
      totalPages
      totalSize
    }
    results {
      ... on commonSearchResultItem {
        ...commonSearchResultItemFields
      }
    }
  }
}
    ${CommonSearchResultItemFieldsFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      pageInput: // value for 'pageInput'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;