import * as React from "react";
import { RteBlock as IRteBlock } from "../../@types/graphql.generated";
import { Rte, Flex, READABLE_LINE_MAX_WIDTH } from "@ui-components";
import { BlockCommonNarrowLayout } from "../../utils/layout";
import { Block } from "../Block";
import createMarkup from "@/utils/createMarkup";

const ArticleRteBlock: React.FC<IRteBlock> = (props) => (
  <Block
    themeColorVariant={props.themeColorVariant || undefined}
    theme={props.theme || undefined}
  >
    <BlockCommonNarrowLayout>
      <Flex
        style={{ alignSelf: "flex-start" }}
        maxw={READABLE_LINE_MAX_WIDTH}
        pr={{ _: 0, l: 39, xxl: 30, xxxl: 22 }}
      >
        <Rte>
          {props.text && (
            <div dangerouslySetInnerHTML={createMarkup(props.text)} />
          )}
        </Rte>
      </Flex>
    </BlockCommonNarrowLayout>
  </Block>
);

export { ArticleRteBlock };
