import { sendMatomoEvent } from "../../utils";

export type contactTargetCategories =
  | "Phone"
  | "Mail"
  | "GöteborgsOperan"
  | "GöteborgsOperan Danskompani";

export const trackFooterContactLinkClick = (
  category: contactTargetCategories,
  target: string
) => {
  sendMatomoEvent({
    event: "Footer Contact Click",
    analyticsInfo: category,
    clickTarget: target,
  });
};
