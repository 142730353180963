import * as React from "react";
import { Flex, Breadcrumbs } from "@ui-components";
import {
  BreadcrumbsBlock as IBreadcrumbsBlock,
  BlockType,
} from "../../@types/graphql.generated";
import { dataToProps } from "./utils";
import { useLocales, translationNamespace } from "../../hooks/useLocales";

const BreadcrumbsBlock: React.FC<IBreadcrumbsBlock> = (props) => {
  const locales = useLocales(
    translationNamespace.block(BlockType.BreadcrumbsBlock),
    ["ariaLabel"]
  );

  return (
    <Flex display="block" style={{ position: "relative" }}>
      {props.links && (
        <Breadcrumbs
          links={dataToProps(props.links)}
          aria-label={locales.ariaLabel}
        />
      )}
    </Flex>
  );
};

export { BreadcrumbsBlock };
