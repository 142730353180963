import * as React from "react";
import {
  Hero,
  ResponsiveImage,
  VideoPlayer,
  mergeColorTheme,
  Flex,
  Button,
  UnderlinedLink,
} from "@ui-components";
import { HeroBlock as IHeroBlock, Link } from "../../@types/graphql.generated";
import { imageDataToImageProps, qBankVideoSrc } from "../../utils";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { ColorTheme } from "../ui-components/src/@types/styled";

export interface HeroBlockProps extends IHeroBlock {
  ctaButton?: Link;
}

const HeroBlock: React.FC<HeroBlockProps> = React.memo((props) => {
  const locales = useLocales(translationNamespace.common("Misc"), [
    "opensInNewTab",
  ]);

  const heroImageProps =
    props.image && props.image.src
      ? imageDataToImageProps(props.image)
      : undefined;

  if (props.theme) {
    return (
      <ThemeProvider theme={mergeColorTheme(props.theme as ColorTheme)}>
        <Flex
          display="block"
          style={{
            backgroundColor: props?.theme?.primaryColor || "",
          }}
        >
          <Hero
            title={props.title || ""}
            preamble={props.preamble || undefined}
            media={
              props.videoUrl ? (
                <VideoPlayer
                  useCustomControls={true}
                  autoplay={true}
                  url={qBankVideoSrc(props.videoUrl)}
                  muted
                  playsinline
                  loop
                />
              ) : heroImageProps ? (
                <ResponsiveImage  {...heroImageProps} />
              ) : null
            }
          >
            {props.ctaButton || props.link ? (
              <Hero.Footer>
                <Flex
                  justifyContent={{ _: "center", m: "flex-start" }}
                  alignItems="center"
                  mx={{ _: -1.5, m: -2 }}
                >
                  {props.ctaButton && (
                    <Flex mx={{ _: 1.5, m: 2 }}>
                      {props.ctaButton.url.indexOf("http") > -1 ? (
                        <Button.Anchor
                          backgroundColor="gold"
                          textColor="black-light"
                          href={props.ctaButton.url}
                          target={`_${props.ctaButton.target}`}
                          rounded
                          label={props.ctaButton.title}
                        />
                      ) : (
                        <Button.Link
                          backgroundColor="gold"
                          textColor="black-light"
                          href={props.ctaButton.url}
                          rounded
                          label={props.ctaButton.title}
                        />
                      )}
                    </Flex>
                  )}
                  {props.link && (
                    <Flex mx={{ _: 1.5, m: 2 }}>
                      {props.link.url.indexOf("http") > -1 ? (
                        <UnderlinedLink.Anchor
                          href={props.link.url}
                          target={`_${props.link.target}`}
                          aria-label={
                            props.link.target === "BLANK"
                              ? `${props.link.title} (${locales.opensInNewTab})`
                              : props.link.title
                          }
                          label={props.link.title}
                        />
                      ) : (
                        <UnderlinedLink.Link
                          href={props.link.url}
                          label={props.link.title}
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
              </Hero.Footer>
            ) : null}
          </Hero>
        </Flex>
      </ThemeProvider>
    );
  }
});
HeroBlock.displayName = "HeroBlock";

export { HeroBlock };
