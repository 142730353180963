import * as React from "react";
import queryString, { ParsedQuery } from "query-string";
import { SearchQueryVariables, SearchQuery } from "./operations.generated";
import { SearchResultItemDefault } from "./components/SearchResultItemDefault";
import { SearchResultItemShow } from "./components/SearchResultItemShow";
import { SearchResultItemProfile } from "./components/SearchResultItemProfile";
import { sendMatomoEvent } from "../../utils";

type SearchResultItemTypeKeys = "showPage" | "profilePage";

const searchResultItemType: Record<SearchResultItemTypeKeys, React.FC<any>> = {
  showPage: SearchResultItemShow,
  profilePage: SearchResultItemProfile,
};

const getSearchResultItemByType = (
  contentType: SearchResultItemTypeKeys
): React.FC<any> => searchResultItemType[contentType] || React.Fragment;

export const mapSearchResultItems = (
  searchQueryData: SearchQuery,
  ref: React.RefObject<HTMLAnchorElement> // React.MutableRefObject<HTMLAnchorElement>
) => {
  const results = searchQueryData.search?.results;

  if (!results) {
    return null;
  }

  return results.map((result, i) => {
    const type = result?.type as SearchResultItemTypeKeys;
    const component =
      result && type && type in searchResultItemType && !result.passed
        ? getSearchResultItemByType(type)
        : SearchResultItemDefault;
    return React.createElement(component, {
      key: `${result?.title?.title}_${i}`,
      result,
      ref: i === 0 ? ref : null,
      index: i,
    });
  });
};

interface SearchQueryStringObject {
  [key: string]: string[] | string;
}

export const parseSearchQueryString = (
  searchQueryString: string
): SearchQueryStringObject => {
  let searchQueryStringObject: SearchQueryStringObject = {};

  try {
    const parsed: ParsedQuery<string> = queryString.parse(searchQueryString, {
      arrayFormat: "bracket",
    });

    searchQueryStringObject = Object.keys(parsed).reduce((acc, key) => {
      const value = parsed[key];
      if (value === null) {
        // Ignore null values
        return acc;
      } else if (Array.isArray(value)) {
        // Filter out null values in arrays
        acc[key] = value.filter((v) => v !== null) as string[];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {} as SearchQueryStringObject);
  } catch {
    searchQueryStringObject = {};
  }

  return searchQueryStringObject;
};

// Parse searchQueryVaribles into url encoded query string
export const searchQueryVariablesToSearchQueryString = (
  searchQueryVariables: SearchQueryVariables
) =>
  queryString.stringify(
    Object.keys(searchQueryVariables).reduce<SearchQueryStringObject>(
      (acc, key) => ({
        ...acc,
        ...(searchQueryVariables.searchInput !== ""
          ? {
              q: searchQueryVariables.searchInput,
              page: searchQueryVariables.pageInput.page.toString(),
            }
          : {}),
      }),
      {}
    ),
    {
      arrayFormat: "bracket",
      sort: false,
    }
  );

/**
 *   Push Motomo event on search
 */
export const trackSearchTermHitsCount = (
  searchTerm: string,
  totalSize: number
) => {
  sendMatomoEvent({
    event: "Search Result Page",
    searchTerm,
    totalSearchHits: totalSize,
  });
};

export const trackSearchResultItemClick = (
  searchTerm: string,
  clickTarget: string,
  searchResultIndex: number,
  page: number,
  totalSize: number
) => {
  sendMatomoEvent({
    event: "Click In Serp",
    searchTerm,
    clickTarget,
    searchResultIndex,
    page,
    totalSearchHits: totalSize,
  });
};
