"use client";
import * as React from "react";
import {
  GetTranslationsDocument,
  GetTranslationsQueryResult,
} from "./operations.generated";
import { Translation } from "../@types/graphql.generated";
import { useSuspenseQuery } from "@apollo/client";

export interface LocalesContextProviderProps {
  localeCode: string;
  children?: React.ReactNode;
}
export interface LocalesContextValue extends LocalesContextProviderProps {
  translations: Record<string, string>;
}

export const LocalesContext = React.createContext<LocalesContextValue>({
  translations: {},
  localeCode: "",
});

function translationsArrayToRecord<T extends string>(
  translations: Translation[]
): Record<T, string> {
  return translations.reduce(
    (acc: Record<T, string>, translation) => ({
      ...acc,
      [translation.key!]: translation.value,
    }),
    {} as any
  );
}

export const LocalesContextProvider: React.FC<LocalesContextProviderProps> = (
  props
) => {
  const translationsQuery = GetTranslationsDocument;
  const translationsQueryResult = useSuspenseQuery(translationsQuery) as GetTranslationsQueryResult;

  return (
    <LocalesContext.Provider
      value={{
        localeCode: props.localeCode,
        translations: translationsQueryResult.data?.translations
          ? translationsArrayToRecord(
              translationsQueryResult.data.translations as Translation[]
            )
          : {},
      }}
    >
      {translationsQueryResult.loading ? "Loading" : props.children}
    </LocalesContext.Provider>
  );
};
