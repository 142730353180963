import * as React from "react";
import {
  Alert,
  UnderlinedLink,
  Flex,
  Text,
  Icon,
  colors,
  darkTheme,
} from "@ui-components";
import { PerformancePastBlock as IPerformancePastBlock } from "../../@types/graphql.generated";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";

const PerformancePastBlock: React.FC<IPerformancePastBlock> = (props) => {
  const locales = useLocales(translationNamespace.common("Misc"), [
    "close",
    "opensInNewTab",
  ]);

  return (
    <ThemeProvider theme={darkTheme}>
      <Alert backgroundColor={colors["black"]}>
        <Alert.Header>
          <Flex flexDirection="row" alignItems="center">
            <Icon icon="notification" mr={2} mt={-0.5} color="white" />
            <Alert.Title>
              <Flex>{props.title}</Flex>
            </Alert.Title>
          </Flex>
        </Alert.Header>
        <Alert.Body>
          <Alert.Title>{props.title}</Alert.Title>
          {props.preamble && (
            <Flex justifyContent={"center"}>
              <Text fontSize={2} lineHeight={3}>
                {props.preamble}
              </Text>
            </Flex>
          )}
          {props.link && (
            <Flex justifyContent={"center"}>
              {props.link.url.indexOf("http") > -1 ? (
                <UnderlinedLink.Anchor
                  label={props.link.title}
                  aria-label={
                    props.link.target === "BLANK"
                      ? `${props.link.title} (${locales.opensInNewTab})`
                      : props.link.title
                  }
                  withIcon={true}
                  href={props.link.url}
                  target={`_${props.link.target}`}
                  rel="noopener noreferrer"
                />
              ) : (
                <UnderlinedLink.Link
                  href={props.link.url}
                  label={props.link.title}
                  withIcon={true}
                />
              )}
            </Flex>
          )}
          <Alert.CloseButton>{locales.close}</Alert.CloseButton>
        </Alert.Body>
      </Alert>
    </ThemeProvider>
  );
};

export { PerformancePastBlock };
