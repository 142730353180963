"use client"
import React from "react";
import { Blocks, BlockType } from "../../@types/graphql.generated";
import {
  RteBlock,
  ArticleRteBlock,
  PageIntroBlock,
  ShowHeroBlock,
  ShowIntroBlock,
  RelatedPagesBlock,
  VideoBlock,
  ProgramListingBlock,
  PageGridBlock,
  LandingPageHeroBlock,
  ThreeColumnPromoBlock,
  MediaContentBlock,
  AccordionBlock,
  BreadcrumbsBlock,
  SlideshowBlock,
  SearchBlock,
  ProfilePageHeroBlock,
  ProfileListingBlock,
  HeroBlock,
  IFrameBlock,
  ParticipantsBlock,
  MultipleShowsBlock,
  AlertBlock,
  PerformancePastBlock,
  PreviewBlock,
  SeasonCalendarBlock,
  TabsBlock,
  ShowAlertBlock,
  VideoPageIntroBlock,
  VideoGridBlock,
  NewsletterBlock,
} from "../";

export interface BlocksResolverProps {
  blocks?: Blocks[];
}

const getBlockTypes = (blockType: BlockType): React.FC<any> =>
  ({
    [BlockType.RteBlock]: RteBlock,
    [BlockType.ArticleRteBlock]: ArticleRteBlock,
    [BlockType.PageIntroBlock]: PageIntroBlock,
    [BlockType.ShowHeroBlock]: ShowHeroBlock,
    [BlockType.ShowIntroBlock]: ShowIntroBlock,
    [BlockType.RelatedPagesBlock]: RelatedPagesBlock,
    [BlockType.VideoBlock]: VideoBlock,
    [BlockType.ProgramListingBlock]: ProgramListingBlock,
    [BlockType.PageGridBlock]: PageGridBlock,
    [BlockType.LandingPageHeroBlock]: LandingPageHeroBlock,
    [BlockType.ThreeColumnPromoBlock]: ThreeColumnPromoBlock,
    [BlockType.MediaContentBlock]: MediaContentBlock,
    [BlockType.AccordionBlock]: AccordionBlock,
    [BlockType.BreadcrumbsBlock]: BreadcrumbsBlock,
    [BlockType.SlideshowBlock]: SlideshowBlock,
    [BlockType.SearchBlock]: SearchBlock,
    [BlockType.ProfilePageHeroBlock]: ProfilePageHeroBlock,
    [BlockType.ProfileListingBlock]: ProfileListingBlock,
    [BlockType.HeroBlock]: HeroBlock,
    [BlockType.IframeBlock]: IFrameBlock,
    [BlockType.ParticipantsBlock]: ParticipantsBlock,
    [BlockType.MultipleShowsBlock]: MultipleShowsBlock,
    [BlockType.AlertBlock]: AlertBlock,
    [BlockType.PerformancePastBlock]: PerformancePastBlock,
    [BlockType.PreviewBlock]: PreviewBlock,
    [BlockType.SeasonCalendarBlock]: SeasonCalendarBlock,
    [BlockType.TabsBlock]: TabsBlock,
    [BlockType.ShowAlertBlock]: ShowAlertBlock,
    [BlockType.VideoPageBlock]: VideoPageIntroBlock,
    [BlockType.VideoGridBlock]: VideoGridBlock,
    [BlockType.NewsletterBlock]: NewsletterBlock,
    [BlockType.FooterBlock]: React.Fragment,
    [BlockType.EcomBlock]: React.Fragment,
    [BlockType.ProgrammeBlock]: React.Fragment,
    [BlockType.Unknown]: React.Fragment,
  }[blockType] || React.Fragment);

const BlockResolver: React.FC<BlocksResolverProps> = (props) => {
  const { blocks: blockList } = props;
  const blocksWithImage = [
    "heroBlock",
    "relatedPagesBlock",
    "mediaContentBlock",
    "multipleShowsBlock",
    "pageGridBlock",
    "threeColumnPromoBlock",
    "landingPageHeroBlock",
  ];

  // Find the first block with an image
  const findFirstImageBlockId = (blocks: Blocks[] | undefined) => {
    if(!blocks) return null;

    for (const block of blocks) {
      if (!block) continue;

      const { __typename, image } = block as any;

      if (blocksWithImage.includes(__typename)) {
        return block.id;
      }

      if (__typename === "pageIntroBlock" && image !== null) {
        return block.id;
      }
    }

    return null;
  };

  const firstImageBlockId = findFirstImageBlockId(blockList);

  return (
    <>
      {blockList?.map((block, index) => {
        if (!block) return null;

        const { __typename, ...rest } = block;

        return React.createElement(getBlockTypes(block.type), {
          ...rest,
          key: block.id || index + 1,
          index,
          isFirstImageBlock: block.id === firstImageBlockId,
        });
      })}
    </>
  );
};

export default BlockResolver;

export { BlockResolver };
