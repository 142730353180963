import { SlideshowItem } from "../../@types/graphql.generated";
import { imageDataToImageProps } from "../../utils";
import { sendMatomoEvent } from "../../utils";

export const dataToProps = (
  items: SlideshowItem[],
  locales?: Record<"participantsLabel" | "photographerLabel", string>
) => {
  return items.map((item) => {
    if (item.image)
      return {
        ...item,
        captionLabel: locales?.participantsLabel,
        creditLabel: locales?.photographerLabel,
        image: imageDataToImageProps(item.image),
      };
  });
};

export const trackSlideshowInteraction = (target: string) => {
  sendMatomoEvent({
    event: "Image Carousel",
    clickTarget: target,
  });
};
