"use client"
import * as React from "react";
import {
  LandingPageHero,
  ResponsiveImage,
  VideoPlayer,
  lightTheme,
  mergeColorTheme,
} from "@ui-components";
import { LandingPageHeroBlock as ILandingPageHeroBlock } from "../../@types/graphql.generated";
import { imageDataToImageProps, qBankVideoSrc } from "../../utils";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import { ColorTheme } from "../ui-components/src/@types/styled";

const LandingPageHeroBlock: React.FC<ILandingPageHeroBlock> = (props) => {
  const heroImageProps =
    props.image && props.image.src
      ? imageDataToImageProps(props.image)
      : undefined;

  return (
    <ThemeProvider
      theme={
        props.theme ? mergeColorTheme(props.theme as ColorTheme) : lightTheme
      }
    >
      <LandingPageHero
        title={props.title || undefined}
        preamble={props.preamble || undefined}
        media={
          props.videoUrl ? (
            <VideoPlayer
              useCustomControls={true}
              autoplay={true}
              url={qBankVideoSrc(props.videoUrl)}
              muted
              playsinline
              loop
            />
          ) : heroImageProps ? (
            <ResponsiveImage {...heroImageProps} />
          ) : null
        }
      />
    </ThemeProvider>
  );
};

export { LandingPageHeroBlock };
