import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { Flex, Text, ProfileCard } from "@ui-components";
import {
  ProfileListingBlock as IProfileListingBlock,
  ProfilePageHeroBlock,
} from "../../@types/graphql.generated";
import { qBankImageSrc } from "../../utils";
import { BlockCommonNarrowLayout } from "../../utils/layout";
import { profileFallbackImageSrc } from "../../utils/fallbackImage";
import { Block } from "../Block";

export interface ProfileListingBlockProps extends IProfileListingBlock {
  profiles: ProfilePageHeroBlock[];
}

const ProfileListingBlock: React.FC<ProfileListingBlockProps> = (props) => (
  <Block
    themeColorVariant={props.themeColorVariant || undefined}
    theme={props.theme || undefined}
  >
    <BlockCommonNarrowLayout>
      <Flex w={1} flexDirection="column" borderBottom>
        {props.title && (
          <Text as="h2" variant="headline-3" mb={{ _: 2, l: 3, xl: 4 }}>
            {props.title}
          </Text>
        )}
        <Flex
          flexWrap="wrap"
          ml={{ _: -1, xl: -1.5 }}
          width={{ _: "calc(100% + 16px)", xl: "calc(100% + 24px)" }}
        >
          {props.profiles.map((profile, i) => (
            <Flex
              flex={{ _: "0 0 50%", s: "0 0 33.33%", xl: "0 0 25%" }}
              flexDirection="column"
              px={{ _: 1, xl: 1.5 }}
              pb={{ _: 2.5, s: 3, m: 4, l: 5 }}
              key={i + "-" + profile.name}
            >
              <ProfileCard
                image={
                  <Image
                    src={
                      profile.image && profile.image.src
                        ? qBankImageSrc(profile.image.src, "600")
                        : profileFallbackImageSrc
                    }
                    alt=""
                    width="600"
                    height="600"
                    sizes="(max-width: 880px) 256px, 384px"
                  />
                }
              >
                {profile.url ? (
                  <Link href={profile.url.url} prefetch={false}>
                    <ProfileCard.Name>{profile.name}</ProfileCard.Name>
                  </Link>
                ) : (
                  <ProfileCard.Name>{profile.name}</ProfileCard.Name>
                )}
                {profile.title && (
                  <Text
                    as="h4"
                    display={"block"}
                    fontSize={{ _: 1.5, xs: 1.75 }}
                    lineHeight={{ _: 1.75, xs: 2 }}
                    mb={1}
                    textTransform={"uppercase"}
                  >
                    {profile.title}
                  </Text>
                )}
                {profile.phone && (
                  <ProfileCard.Phone href={`tel:${profile.phone}`}>
                    {profile.phone}
                  </ProfileCard.Phone>
                )}
                {profile.email && (
                  <ProfileCard.Email href={`mailto:${profile.email}`}>
                    {profile.email}
                  </ProfileCard.Email>
                )}
              </ProfileCard>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </BlockCommonNarrowLayout>
  </Block>
);

export { ProfileListingBlock };
