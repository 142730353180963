"use client"
import React from "react"
import { useEffect } from "react"
import { scale, HEADER_HEIGHT } from "@ui-components"

export interface Config {}

/**
 *   Hook for anchor link scrolling
 */
export const useAnchorLinkScrolling = () => {
	const headerOffset = scale(HEADER_HEIGHT)
	const headerOffsetRef = React.useRef(headerOffset)

	const clickHandler = React.useCallback((e: any) => {
		const target = e.target
		const anchorLinksSelector = 'a[href*="#"]'

		if (target) {
			const isTarget = target.matches
				? target.matches(anchorLinksSelector)
				: target.msMatchesSelector(anchorLinksSelector)

			if (
				isTarget ||
				(target.closest(anchorLinksSelector) &&
					target.closest(anchorLinksSelector).href)
			) {
				const id = target.closest(anchorLinksSelector).href.split("#")[1]
				const targetBlock = document.getElementById(id)

				if (targetBlock) {
					e.preventDefault()
					const targetOffset = parseInt(
						target.getAttribute("data-target-offset") ||
							target.parentNode.getAttribute("data-target-offset")
					)
					window.scroll({
						top: targetOffset
							? targetBlock.offsetTop - targetOffset - headerOffsetRef.current
							: targetBlock.offsetTop - headerOffsetRef.current,
						left: 0,
						behavior: "smooth"
					})
				}
			}
		}
	}, [])

	useEffect(() => {
		document.addEventListener("click", clickHandler, false)
	}, [])

	useEffect(() => {
		headerOffsetRef.current = headerOffset
	}, [headerOffset])
}
