"use client"
import * as React from "react";
import { Slideshow, Flex, INNER_CONTAINER_MAX_WIDTH } from "@ui-components";
import { Block } from "../Block/Block";
import {
  SlideshowItem,
  SlideshowBlock as ISlideshowBlock,
  BlockType,
} from "../../@types/graphql.generated";
import { trackSlideshowInteraction } from "./utils";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { BlockTitlePreamble } from "../../utils/layout";

export interface SlideshowBlockProps extends ISlideshowBlock {
  slideshowItems: SlideshowItem[];
}

const SlideshowBlock: React.FC<SlideshowBlockProps> = (props) => {
  const locales = useLocales(
    translationNamespace.block(BlockType.SlideshowBlock),
    [
      "participantsLabel",
      "photographerLabel",
      "nextSlideLabel",
      "previousSlideLabel",
    ]
  );
  const [userHasInteracted, setUserHasInteracted] = React.useState(false);

  // Ensure slideshowItems are properly structured and filtered
  const slideshowItems = React.useMemo(() => {
    return props.slideshowItems.map((item) => ({
      captionLabel: item.captionLabel || "",
      caption: item.caption || "",
      creditLabel: item.creditLabel || "",
      credit: item.credit || "",
      image: {
        src: item.image?.src || "",
        alt: item.image?.alt || "",
      },
    }));
  }, [props.slideshowItems]);

  return (
    <Block
      themeColorVariant={props.themeColorVariant || undefined}
      theme={props.theme || undefined}
    >
      <Flex py={{ _: 8, l: 8 }} px={{ _: 0, l: 20 }}>
        <Flex
          alignItems="center"
          flexDirection="column"
          w={1}
          h={1}
          maxw={INNER_CONTAINER_MAX_WIDTH}
          style={{ margin: "0 auto" }}
        >
          {props.title && (
            <BlockTitlePreamble
              title={props.title}
              preamble={props.preamble || undefined}
            />
          )}
          {slideshowItems.length > 0 && (
            <Slideshow
              items={slideshowItems}
              themeColorVariant={props.themeColorVariant || undefined}
              onClickListener={() => {
                if (!userHasInteracted) {
                  trackSlideshowInteraction(window?.location?.pathname);
                  setUserHasInteracted(true);
                }
              }}
              locales={{
                nextSlide: locales.nextSlideLabel,
                previousSlide: locales.previousSlideLabel,
                participantsLabel: locales.participantsLabel,
                photoLabel: locales.photographerLabel,
              }}
            />
          )}
        </Flex>
      </Flex>
    </Block>
  );
};

export { SlideshowBlock };
