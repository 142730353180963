"use client";
import * as React from "react";
import {
  Flex,
  BlockAlert,
  Icon,
  IconTypes,
  Rte,
  READABLE_LINE_MAX_WIDTH,
} from "@ui-components";
import { ShowAlertBlock as IShowAlertBlock } from "../../@types/graphql.generated";
import { ThemeContext } from "styled-components";
import createMarkup from "@/utils/createMarkup";

const ShowAlertBlock: React.FC<IShowAlertBlock> = (props) => {
  const theme = React.useContext(ThemeContext);

	return (
		<Flex display="block" style={{ margin: "0 auto" }} py={{ _: 8, l: 8 }}>
			<Flex
				maxw={READABLE_LINE_MAX_WIDTH}
				w={1}
				style={{ margin: "0 auto" }}
				pl={{ _: 2, m: 5, l: 11 }}
				pr={{ _: 2, m: 5, l: 11 }}
			>
				<BlockAlert 
					showMore={props.more ? (
						<Rte>
							<div dangerouslySetInnerHTML={createMarkup(props.more)} />
						</Rte>
					) : undefined}
					color={theme?.dark ? "white" : "black"}
				>
					<Flex pl={{ _: 0, m: 1, l: 0 }}>
					<BlockAlert.Icon>
						<Icon
							icon={props.icon.toLowerCase() as IconTypes}
							color={theme?.dark ? "white" : "black"}
							size={{ _: 1.75, m: 2.5, l: 3 }}
						/>
					</BlockAlert.Icon>
					{props.body && (
						<Rte>
							<div dangerouslySetInnerHTML={createMarkup(props.body)} />
						</Rte>
					)}
					</Flex>
				</BlockAlert>
			</Flex>
		</Flex>
	)
}

export { ShowAlertBlock };
