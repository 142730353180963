import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { ThreeColumnPromoBlock as IThreeColumnPromoBlock } from "../../@types/graphql.generated";
import { Flex, CircledImagePromo } from "@ui-components";
import { Block } from "../Block/Block";
import { qBankImageSrc, FallbackImage } from "../../utils";
import { BlockTitlePreamble } from "../../utils/layout";
import { BlockCommonNarrowLayout } from "../../utils/layout";

export interface ThreeColumnPromoBlockProps extends IThreeColumnPromoBlock {
  index: number;
  isFirstImageBlock: boolean;
}

const ThreeColumnPromoBlock: React.FC<ThreeColumnPromoBlockProps> = (props) => {
  const pageLength = props?.pages?.length;

  return (
    <Block
      themeColorVariant={props.themeColorVariant || undefined}
      theme={props.theme || undefined}
    >
      <BlockCommonNarrowLayout>
        {props.title && (
          <BlockTitlePreamble
            title={props.title}
            preamble={props.preamble || undefined}
          />
        )}
        <Flex
          w={1}
          style={{ margin: "0 auto" }}
          flexDirection={{ _: "column", m: "row" }}
          justifyContent={pageLength === 3 ? "space-between" : "center"}
        >
          {Array.isArray(props?.pages) &&
            props.pages.map((page: any, i: number) => {
              const firstChild = i === 0;
              const lastChild = i + 1 === pageLength;

              if (page.url) {
                return (
                  <Link href={page.url} key={i + "-" + page.url} prefetch={false}>
                    <Flex
                      my={{ _: 3, m: 0 }}
                      ml={{
                        _: 0,
                        m: firstChild ? 0 : 2,
                        xl: firstChild ? 0 : 4,
                      }}
                      mr={{
                        _: 0,
                        m: lastChild ? 0 : 2,
                        xl: lastChild ? 0 : 4,
                      }}
                      justifyContent="center"
                    >
                      <CircledImagePromo
                        media={
                          page.image && page.image.src ? (
                            <Image
                              src={qBankImageSrc(page.image.src, "750")}
                              alt=""
                              width="750"
                              height="1000"
                              sizes="(max-width: 1240px) 200px, 300px"
                              priority={props.isFirstImageBlock}
                            />
                          ) : (
                            <FallbackImage />
                          )
                        }
                        {...page}
                        title={page.title || undefined}
                        preamble={page.preamble || undefined}
                      />
                    </Flex>
                  </Link>
                );
              }
              return null;
            })}
        </Flex>
      </BlockCommonNarrowLayout>
    </Block>
  );
};

export { ThreeColumnPromoBlock };
