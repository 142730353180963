import { LocalesContext } from "../context"
import React from "react"

/**
 * Retutns a namespaced translation key
 */
const getNamespacedTranslationKey = (namespace: string, key: string) =>
	`Website_${namespace}_${key}`

/**
 * Util for constructing known namespaces
 */
export const translationNamespace = {
	/**
	 * Expects the BlockType as it's namespace
	 * i.e namespace(BlockType.Anchorlinkblock)
	 */
	block: (namespace: string) => `Blocks_${namespace}`,
	common: (namespace: string) => `Common_${namespace}`
}

/**
 * Retutns a Record of translations by a tranlation lookup key
 * If no mathing translation is found the lookup key is returned as a placeholder
 * @param namespace translation key namespace
 * @param lookup array of lookup keys
 */
export function useLocales<T, P extends string>(
	namespace: string,
	lookup: P[]
): Record<P, string> {
	const { translations } = React.useContext(LocalesContext)

	return lookup.reduce((acc, key) => {
		const translationLookupKey = getNamespacedTranslationKey(namespace, key)

		return {
			...acc,
			...{
				[key]: translations[translationLookupKey]
					? translations[translationLookupKey]
					: translationLookupKey
			}
		}
	}, {}) as any
}
