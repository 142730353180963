"use client"
import * as React from "react";
import { usePathname } from "next/navigation";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import Image from "next/image";

// components
import {
  Flex,
  INNER_CONTAINER_MAX_WIDTH,
  Rte,
  VideoPlayer,
  mergeColorTheme,
  Text,
  BlockAlert,
  Icon,
} from "../ui-components/src";

// types
import {
  BlockType,
  VideoPageIntroBlock as IVideoPageIntroBlock,
} from "../../@types/graphql.generated";

// utils
import {
  localeCodeFromUrl,
  trackVideoInteraction,
  BlockFullWidthLayout,
  BlockTitlePreamble,
  formatDateSpan,
} from "@/utils";

// hooks
import { translationNamespace, useLocales } from "../../hooks";
import { ColorTheme } from "../ui-components/src/@types/styled";

// functions
import createMarkup from "@/utils/createMarkup";

const VideoPageIntroBlock: React.FC<IVideoPageIntroBlock> = (props) => {
  const pathname = usePathname();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [progressEventSent, setProgressEventSent] = React.useState(false);
  const localeCode = localeCodeFromUrl(pathname);
  const locales = useLocales(
    translationNamespace.block(BlockType.VideoPageBlock),
    ["currentlyNotPlaying", "notAvailable"]
  );

  const startDate = props?.fromDate && new Date(props.fromDate);
  const endDate = props?.toDate && new Date(props.toDate);
  const description =
    startDate && endDate && `${formatDateSpan(startDate, endDate, localeCode)}`;

  return (
    <ThemeProvider theme={mergeColorTheme(props.theme as ColorTheme)}>
      <BlockFullWidthLayout>
        <Flex
          px={{ _: 0, l: 10 }}
          w={{ _: "100%" }}
          maxw={INNER_CONTAINER_MAX_WIDTH}
          alignItems="center"
          flexDirection="column"
        >
          {props.videoURL != null && props.videoDetails != null ? (
            <VideoPlayer
              url={props.videoURL}
              loadWhenInView={true}
              onStart={() => {
                trackVideoInteraction("Play", location.pathname);
                setIsPlaying(true);
              }}
              onProgress={({ played }) => {
                if (
                  isPlaying &&
                  !progressEventSent &&
                  parseFloat(played.toFixed(1)) === 0.5
                ) {
                  trackVideoInteraction("50% progress", location.pathname);
                  setProgressEventSent(true);
                }
              }}
              onEnded={() => {
                trackVideoInteraction("Complete", location.pathname);
                setIsPlaying(false);
              }}
            />
          ) : props.videoDetails != null &&
            props.videoDetails.videoThumbnail ? (
            <div
              style={{
                position: "relative",
                height: "0",
                width: "100%",
                paddingTop: "56.25%",
              }}
            >
              <Image
                alt={props.title || ""}
                src={props.videoDetails.videoThumbnail}
                style={{ objectFit: "contain" }}
                fill
								priority
              />
            </div>
          ) : (
            <Flex
              w="100%"
              pt="56.25%"
              backgroundColor={props?.theme?.dark ? "grey-light" : "grey-dark"}
            ></Flex>
          )}

          <Flex mt={{ _: 2 }} maxw={INNER_CONTAINER_MAX_WIDTH}>
            {props.videoDetails == null || props.videoURL == null ? (
              <BlockAlert>
                <BlockAlert.Icon>
                  <Icon
                    mt={{ _: -0.375, l: -1.25 }}
                    icon="red_alert"
                    color={props?.theme?.dark ? "white" : "black"}
                    size={{ _: 1.75, l: 3.5 }}
                  />
                </BlockAlert.Icon>
                <Flex textColor={props?.theme?.dark ? "white" : "black"}>
                  {props.videoDetails
                    ? locales.currentlyNotPlaying
                    : locales.notAvailable}
                </Flex>
              </BlockAlert>
            ) : (
              <></>
            )}
          </Flex>
          <Text
            as="span"
            display="block"
            textAlign="center"
            variant="tag"
            pt={3}
            pb={{ _: 0, m: 5 }}
            fontSize={1.5}
            lineHeight={2.25}
          >
            {description}
          </Text>
          {props.title || props.preamble ? (
            <Flex pt={{ _: description ? 0 : 5 }}>
              <BlockTitlePreamble
                title={props.title || ""}
                preamble={props.preamble || undefined}
              />
            </Flex>
          ) : null}
          <Flex w={1} flexDirection="column" px={{ _: 5, m: 0 }}>
            <Rte>
              {props.text && (
                <div dangerouslySetInnerHTML={createMarkup(props.text)} />
              )}
            </Rte>
          </Flex>
        </Flex>
      </BlockFullWidthLayout>
    </ThemeProvider>
  );
};

export { VideoPageIntroBlock };
