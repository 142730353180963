"use client";
import * as React from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import Image from "next/image";

// types
import { VideoGridBlock as IVideoGridBlock } from "../../@types/graphql.generated";

// utils
import {
  localeCodeFromUrl,
  formatDateSpan,
  BlockFullWidthLayout,
  BlockTitlePreamble,
} from "@/utils";

// components
import { Flex, EventCard } from "@ui-components";
import { Block } from "../Block";

const VideoGridBlock: React.FC<IVideoGridBlock> = (props) => {
  const pathname = usePathname();
  const localeCode = localeCodeFromUrl(pathname);
  const videoPages = props?.videoPages;

  return (
    <Block
      themeColorVariant={props.themeColorVariant || undefined}
      theme={props.theme || undefined}
    >
      <BlockFullWidthLayout>
        <Flex w={1}>
          <BlockTitlePreamble
            title={props.title || ""}
            preamble={props.description || undefined}
          />
        </Flex>
        <Flex
          display={{ _: "block", s: "flex" }}
          flexWrap="wrap"
          width={{ _: 1, s: "calc(100% + 16px)", xl: "calc(100% + 32px)" }}
          ml={{ s: -1, xl: -2 }}
        >
          {videoPages && videoPages.length > 0 ? (
            videoPages.map((page: any, i: number) => {
              const startDate = page.fromDate && new Date(page.fromDate);
              const endDate = page.toDate && new Date(page.toDate);
              const description =
                startDate &&
                endDate &&
                `${formatDateSpan(startDate, endDate, localeCode)}`;

              return (
                <Flex
                  key={i + "-" + page.link.title.replace(/\s+/g, '')}
                  flex={{
                    _: "0 0 100%",
                    s: "0 0 50%",
                    l: "0 0 33.33%",
                    xxxl: "0 0 25%",
                  }}
                  flexDirection="column"
                  px={{ _: 0, s: 1, xl: 1.5 }}
                  pb={{ _: 0, s: 2, xl: 3 }}
                >
                  <Link
                    href={page.link.url}
                    title={page.preamble || page.link.title || ""}
                    style={{ width: "100%", height: "100%" }}
                    prefetch={false}
                  >
                    <EventCard
                      name={page.title}
                      meta={description}
                      media={
                        <Image
                          alt={page.title}
                          src={page.videoDetails.videoThumbnail}
                          width="1024"
                          height="526"
                          sizes="(min-width: 430px) and (max-width: 639px) 640px, (min-width: 1970px) 640px, 385px"
                        />
                      }
                      title={page.preamble}
                    />
                  </Link>
                </Flex>
              );
            })
          ) : (
            null
          )}
        </Flex>
      </BlockFullWidthLayout>
    </Block>
  );
};

export { VideoGridBlock };
