"use client";
import * as React from "react";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import { Theme, ThemeColorVariant } from "../@types/graphql.generated";
import { mergeColorTheme, theme, lightTheme } from "@ui-components";
import throttle from "lodash/throttle";
import { ColorTheme } from "@/components/ui-components/src/@types/styled";

export interface ColorThemeContextValue {
  setColorTheme: React.Dispatch<React.SetStateAction<Theme>>;
  setThemeColorBackground: (
    inView: boolean,
    themeColorVariant: ThemeColorVariant
  ) => void;
}
export const ColorThemeContext = React.createContext<ColorThemeContextValue>({
  setColorTheme: (Theme) => {},
  setThemeColorBackground: () => {},
});

export const ColorThemeProvider: React.FC<React.PropsWithChildren<{defaultTheme?:Theme}>> = (
  { defaultTheme,...props}
) => {
  const [currentTheme, setColorTheme] = React.useState<Theme>(defaultTheme || lightTheme);
  const [isPrimaryThemeColor, setIsPrimaryThemeColor] = React.useState(false);

  // Listen for change in ThemeColorVariant sent from blocks
  const setThemeColorBackground = throttle(
    (inView: boolean, themeColorVariant: ThemeColorVariant) => {
      if (inView) {
        themeColorVariant === "PRIMARY"
          ? setIsPrimaryThemeColor(true)
          : setIsPrimaryThemeColor(false);
      } else {
        themeColorVariant === "PRIMARY"
          ? setIsPrimaryThemeColor(false)
          : setIsPrimaryThemeColor(true);
      }
    },
    100,
    { trailing: false }
  );

  const primaryColor = currentTheme.primaryColor ?? lightTheme.primaryColor;
  const complementaryColor = currentTheme.complementaryColor ?? lightTheme.complementaryColor;
  const themeColorBackground = isPrimaryThemeColor ? primaryColor : complementaryColor

  return (
    <ColorThemeContext.Provider
      value={{ setColorTheme, setThemeColorBackground }}
    >
      <ThemeProvider
        theme={{
          // merge base theme ...
          ...theme,
          // ... with color theme
          ...(currentTheme ? mergeColorTheme(currentTheme as ColorTheme) : lightTheme),
          // themeColorBackground
          ...(themeColorBackground && {
            themeColorBackground: themeColorBackground,
          }),
        }}
        {...props}
      />
    </ColorThemeContext.Provider>
  );
};
