import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { qBankImageSrc } from "../../../utils";
import { Flex, Icon, SearchResultItem, Text } from "@ui-components";
import {
  BlockType,
  CommonSearchResultItem,
} from "../../../@types/graphql.generated";
import { translationNamespace, useLocales } from "../../../hooks";
import { tiemposHeadline } from "@/app/fonts";

interface SearchResultitemDefaultProps {
  result: CommonSearchResultItem;
  index: number;
}

export const SearchResultItemDefault = React.forwardRef<
  HTMLAnchorElement,
  SearchResultitemDefaultProps
>((props, ref) => {
  const locales = useLocales(
    translationNamespace.block(BlockType.SearchBlock),
    ["passedShowMessage"]
  );

  return (
    <SearchResultItem
      image={
        props.result.image && props.result.image.src ? (
          <Image
            src={qBankImageSrc(props.result.image.src, "768")}
            alt={props.result.image.alt || ""}
            width="768"
            height="512"
            sizes="(max-width: 639px) 100px, 300px"
            priority={props.index <= 2}
          />
        ) : null
      }
    >
      <SearchResultItem.Header>
        {props.result.tag && (
          <SearchResultItem.Tag>{props.result.tag}</SearchResultItem.Tag>
        )}
        {props.result.dateRange && props.result.dateRange.from && (
          <SearchResultItem.Date>
            {props.result.dateRange.to
              ? `${props?.result?.dateRange?.from?.formatted?.date} - ${props?.result?.dateRange?.to?.formatted?.date}`
              : props?.result?.dateRange?.from?.formatted?.date}
          </SearchResultItem.Date>
        )}
      </SearchResultItem.Header>
      {props.result.title && (
        <Link
          ref={ref}
          href={props.result.title.url}
          className={tiemposHeadline.className}
          prefetch={false}
        >
          <Text
            as="h2"
            fontSize={{ _: 2.25, m: 3, xl: 4 }}
            lineHeight={{ _: 3, m: 4, xl: 5 }}
            textColor="black"
            mb={{ _: 1, m: 0.5 }}
            style={{ textDecoration: "underline" }}
          >
            {props.result.title.title}
          </Text>
        </Link>
      )}
      {props.result.preamble && (
        <SearchResultItem.Content>
          <Text
            fontSize={{ _: 2, xl: 2.25 }}
            lineHeight={{ _: 2.5, s: 3.25 }}
            mb={0}
            truncate="threeLine"
          >
            {props.result.preamble}
          </Text>
        </SearchResultItem.Content>
      )}
      {props.result.passed && (
        <Flex
          backgroundColor="greybrown"
          p={1}
          alignItems="center"
          mt={{ _: 1, m: 2 }}
          maxw={{ _: 1, m: "fit-content" }}
        >
          <Icon icon={"notification"} color="black" mr={1} size={2} />
          <Text fontSize={1.75} lineHeight={2} mb={0}>
            {locales.passedShowMessage}
          </Text>
        </Flex>
      )}
    </SearchResultItem>
  );
});
SearchResultItemDefault.displayName = "SearchResultItemDefault";
