"use client";
import * as React from "react";
import Image from "next/image";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import {
  Flex,
  Text,
  Card,
  mergeColorTheme,
  UnderlinedLink,
  ScrollPanel,
  scale,
} from "@ui-components";
import {
  RelatedPagesBlock as IRelatedPagesBlock,
  PageSummary,
  BlockType,
} from "../../@types/graphql.generated";
import { Block } from "../Block";
import Link from "next/link";
import { qBankImageSrc, FallbackImage } from "../../utils";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { Maybe } from "graphql/jsutils/Maybe";
import { ColorTheme } from "../ui-components/src/@types/styled";

export interface RelatedPagesBlockProps extends IRelatedPagesBlock {
  index: number;
  isFirstImageBlock: boolean;
}

const RelatedPagesBlock: React.FC<RelatedPagesBlockProps> = (props) => {
  const locales = useLocales(
    translationNamespace.block(BlockType.RelatedPagesBlock),
    ["nextButtonLabel", "prevButtonLabel"]
  );
  const slideRef = React.useRef<HTMLDivElement>(null);
  const cta = props.cta;

  return (
    props.pages &&
    props.pages?.length > 0 && (
      <Block
        themeColorVariant={props.themeColorVariant || undefined}
        theme={props.theme || undefined}
      >
        <Flex
          flexDirection="column"
          pt={{ _: 8 }}
          pb={{ _: 16, xxs: 8 }}
          as="section"
          alignItems="center"
        >
          {props.title && cta && (
            <Flex
              flexDirection="column"
              w={1}
              px={{ _: 0, s: 5, l: 10 }}
              pb={{ _: 8 }}
            >
              <Flex
                flexDirection="column"
                w={1}
                maxw={180}
                style={{ margin: "0 auto" }}
              >
                <Flex
                  justifyContent={{ _: "center", m: "space-between" }}
                  alignItems="center"
                >
                  {props.title && (
                    <Flex flex={1} justifyContent={{ _: "center", m: "unset" }}>
                      <Text
                        variant="headline-2"
                        pr={{ m: 2 }}
                        mb={{ _: 0 }}
                        textAlign={{ _: "center", m: "left" }}
                      >
                        {props.title}
                      </Text>
                    </Flex>
                  )}

                  {cta && cta.url && (
                    <Flex display={{ _: "none", m: "flex" }}>
                      <UnderlinedLink.Link
                        href={cta.url}
                        label={cta.label || undefined}
                        withIcon
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}

          {/* Page card carousel */}
          <ScrollPanel
            slideRef={slideRef as React.MutableRefObject<HTMLDivElement>}
          >
            <ScrollPanel.Content>
              <Flex py={0} pr={{ m: 8 }} w={1}>
                <Flex maxw={184}>
                  {props.pages.map((page: Maybe<PageSummary>, i: number) =>
                    page && page.url ? (
                      <Flex
                        flex={1}
                        minw={{ _: 42, m: 50 }}
                        px={{ _: 1, xl: 1.5 }}
                        ref={i === 0 ? slideRef : null}
                        key={i + "-" + page.pageId}
                      >
                        <ThemeProvider
                          theme={mergeColorTheme(page.theme as ColorTheme)}
                        >
                          <Link href={page.url} style={{ width: "100%" }} prefetch={false}>
                            <Card
                              {...page}
                              title={page.preamble || undefined}
                              tag={page.tag || undefined}
                              name={page.name || undefined}
                              meta={page.meta || undefined}
                              media={
                                page.image && page.image.src ? (
                                  <Image
                                    alt=""
                                    src={qBankImageSrc(page?.image?.src, "768")}
                                    width="768"
                                    height="512"
                                    sizes="(max-width: 768px) 384px, 640px"
                                    priority={props.isFirstImageBlock}
                                  />
                                ) : (
                                  <FallbackImage />
                                )
                              }
                              bgGradient={false}
                              editorsPick={page.editorsPick || undefined}
                            />
                          </Link>
                        </ThemeProvider>
                      </Flex>
                    ) : null
                  )}
                </Flex>
              </Flex>
            </ScrollPanel.Content>
            <Flex position="relative" w={1}>
              <Flex
                position="absolute"
                flexDirection={{ _: "column-reverse", xxs: "row" }}
                alignItems={{ m: "flex-start" }}
                px={{ _: 2, s: 5 }}
                justifyContent={{ m: "space-between" }}
                w={1}
                top={{ _: scale.px(1) }}
              >
                {cta && cta.url && (
                  <Flex
                    display={{ _: "flex", m: "none" }}
                    justifyContent="flex-end"
                  >
                    <UnderlinedLink.Link
                      href={cta.url}
                      label={cta.label || undefined}
                      withIcon
                    />
                  </Flex>
                )}
                <Flex flex={1} justifyContent="flex-end">
                  <ScrollPanel.Navigation {...locales} />
                </Flex>
              </Flex>
            </Flex>
          </ScrollPanel>
        </Flex>
      </Block>
    )
  );
};

export { RelatedPagesBlock };
