import { BreadcrumbsLink } from "@ui-components";

export interface Link {
  title: string;
  url: string;
}

export const dataToProps = (links: Link[]): BreadcrumbsLink[] =>
  links.map((link) => {
    const { title, url } = link;

    return {
      id: url,
      title,
      url,
    };
  });
