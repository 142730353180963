import * as React from "react";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import Link from "next/link";
import Image from "next/image";

// components
import {
  VideoPlayer,
  Flex,
  mergeColorTheme,
  MediaContent,
} from "@ui-components";
import { Block } from "../Block/Block";

// types
import { ColorTheme } from "../ui-components/src/@types/styled";
import {
  MediaContentBlock as IMediaContentBlock,
  PageSummary,
} from "../../@types/graphql.generated";

// utils
import { qBankImageSrc, qBankVideoSrc, FallbackImage } from "../../utils";
import { BlockCommonLayout, BlockTitlePreamble } from "../../utils/layout";

export interface MediaContentBlockProps extends IMediaContentBlock {
  index: number;
  isFirstImageBlock: boolean;
}

const MediaContentBlock: React.FC<MediaContentBlockProps> = (props) => {
  const pages = props.pages;

  return (
    <Block
      themeColorVariant={props.themeColorVariant || undefined}
      theme={props.theme || undefined}
    >
      <BlockCommonLayout>
        <Flex w={1} flexDirection="column">
          {props.title && (
            <BlockTitlePreamble
              title={props.title}
              preamble={props.preamble || undefined}
            />
          )}
          {pages &&
            pages.length > 0 &&
            pages?.map((page: PageSummary | null, i: number) => {
              if (page && page.url && page.theme && pages.length > 0) {
                // Used to remove margin on last child
                const lastChild = i === pages.length - 1;

                // Even/odd logic for mirror layout
                const useMirrorLayout =
                  i % 2 === 0 ? props.mirrorLayout : !props.mirrorLayout;

                return (
                  <Flex
                    ml={useMirrorLayout ? { _: 0, xl: 27 } : 0}
                    mr={useMirrorLayout ? 0 : { _: 0, xl: 27 }}
                    key={i + "-" + page?.pageId}
                  >
                    <Link href={page.url} style={{ width: "100%" }} prefetch={false}>
                      <Flex mb={{ _: lastChild ? 0 : 4, m: lastChild ? 0 : 8 }}>
                        <ThemeProvider
                          theme={mergeColorTheme(page.theme as ColorTheme)}
                        >
                          <MediaContent
                            media={
                              page.videoUrl ? (
                                <VideoPlayer
                                  useCustomControls={true}
                                  autoplay={true}
                                  url={qBankVideoSrc(page.videoUrl)}
                                  muted
                                  playsinline
                                  loop
                                />
                              ) : page.image && page.image.src ? (
                                <Image
                                  alt=""
                                  src={qBankImageSrc(page.image.src, "1600")}
                                  style={{ width: "100%" }}
                                  width="1600"
                                  height="900"
                                  sizes="(max-width: 967px) 100vw, (min-width: 968px) and (max-width: 2080) 60vw, 1600px"
                                  priority={props.isFirstImageBlock}
                                />
                              ) : (
                                <FallbackImage />
                              )
                            }
                            mirrorLayout={useMirrorLayout || undefined}
                            bgColor={
                              (page.theme && page.theme.primaryColor) ||
                              undefined
                            }
                            textColor={
                              (page.theme && page.theme.textColor) || undefined
                            }
                            {...page}
                            title={page.title || undefined}
                            preamble={page.preamble || undefined}
                          />
                        </ThemeProvider>
                      </Flex>
                    </Link>
                  </Flex>
                );
              }
              return null;
            })}
        </Flex>
      </BlockCommonLayout>
    </Block>
  );
};

export { MediaContentBlock };
