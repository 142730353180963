import * as React from "react";
import styled from "styled-components";
import { Theme } from "../../@types/graphql.generated";
import { isDefaultTheme } from "../ui-components/src";

export const ThemeColorBackgroundPrimary = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.complementaryColor};
  z-index: -1;
`;

export const ThemeColorBackgroundComplementary = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1.5s ease;
  background-color: ${({ theme }) =>
    isDefaultTheme(theme) ? theme.complementaryColor : theme.primaryColor};
  opacity: ${({ theme }) =>
    theme.themeColorBackground === theme.complementaryColor ? 1 : 0};
  z-index: -1;
`;

export interface ThemeColorBackgroundProps {
  theme?: Theme;
  children?: React.ReactNode;
}

export const ThemeColorBackground: React.FC<ThemeColorBackgroundProps> =
  React.memo((props) => {
    return (
      <>
        <ThemeColorBackgroundPrimary />
        <ThemeColorBackgroundComplementary />
        {props.children}
      </>
    );
  });
ThemeColorBackground.displayName = "ThemeColorBackground";
