import * as React from "react";
import {
  Alert,
  UnderlinedLink,
  Flex,
  Text,
  lightTheme,
} from "@ui-components";
import { AlertBlock as IAlertBlock } from "../../@types/graphql.generated";
import { ClientThemeProvider as ThemeProvider } from "@/components/ClientThemeProvider";
import { useLocales, translationNamespace } from "../../hooks/useLocales";

const AlertBlock: React.FC<IAlertBlock> = (props) => {
  const locales = useLocales(translationNamespace.common("Misc"), [
    "close",
    "opensInNewTab",
  ]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Alert backgroundColor={props.backgroundColor || undefined}>
        <Alert.Header>
          <Alert.Title>{props.title}</Alert.Title>
        </Alert.Header>
        <Alert.Body>
          <Alert.Title>{props.title}</Alert.Title>
          <Flex justifyContent={"center"}>
            <Text fontSize={2} lineHeight={3}>
              {props.preamble}
            </Text>
          </Flex>
          {props.link && (
            <Flex justifyContent={"center"}>
              {props.link.url.indexOf("http") > -1 ? (
                <UnderlinedLink.Anchor
                  label={props.link.title}
                  aria-label={
                    props.link.target === "BLANK"
                      ? `${props.link.title} (${locales.opensInNewTab})`
                      : props.link.title
                  }
                  withIcon={true}
                  href={props.link.url}
                  target={`_${props.link.target}`}
                  rel="noopener noreferrer"
                />
              ) : (
                <UnderlinedLink.Link
                  href={props.link.url}
                  label={props.link.title}
                  withIcon={true}
                />
              )}
            </Flex>
          )}
          <Alert.CloseButton>{locales.close}</Alert.CloseButton>
        </Alert.Body>
      </Alert>
    </ThemeProvider>
  );
};

export { AlertBlock };
