import * as React from "react";
import { Flex, INNER_CONTAINER_MAX_WIDTH } from "@ui-components";
import { Block } from "@components";
import { BlockTitlePreamble } from "../../utils/layout";
import { SeasonCalendarBlockFieldsFragment as ISeasonCalendarBlock } from "./operations.generated";
import { Filter, Calendar } from "./components";
import { SeasonCalendarContextProvider } from "./context/SeasonCalendarContext";

const SeasonCalendarBlock: React.FC<ISeasonCalendarBlock> = (props) => (
  <Block
    themeColorVariant={props.themeColorVariant || undefined}
    theme={props.theme || undefined}
  >
    <Flex id={props.type} px={{ _: 0, m: 5, l: 10 }} py={{ _: 4, m: 6, l: 8 }}>
      <Flex
        alignItems="center"
        flexDirection="column"
        w={1}
        maxw={INNER_CONTAINER_MAX_WIDTH}
        style={{ margin: "0 auto" }}
      >
        <Flex flexDirection="column" w={1} px={{ _: 2, m: 0 }}>
          {props.title && (
            <BlockTitlePreamble
              title={props.title}
              preamble={props.preamble || undefined}
            />
          )}
        </Flex>
        {props.content && (
          <Flex w={1} flexDirection="column">
            <SeasonCalendarContextProvider>
              <Filter {...props} />
              <Calendar {...props.content} />
            </SeasonCalendarContextProvider>
          </Flex>
        )}
      </Flex>
    </Flex>
  </Block>
);

export { SeasonCalendarBlock };
