"use client";
import React from "react";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { Text, Select } from "@ui-components";
import {
  LanguageSwitchQueryVariables,
  LanguageSwitchDocument,
  LanguageSwitchQueryResult,
} from "./operations.generated";
import { LocalesContext } from "../../context/LocalesContext";
import { usePathname } from "next/navigation";
import { useSuspenseQuery } from "@apollo/client";

export interface LanguageSwitchProps {
  id?: string;
  useShortTitle?: boolean;
  useLabel?: boolean;
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = (props) => {
  const locales = useLocales(translationNamespace.common("LanguageSwitch"), [
    "primaryShortTitle",
    "secondaryShortTitle",
    "primaryTitle",
    "secondaryTitle",
    "label",
  ]);

  const pathname = usePathname();
  const { localeCode } = React.useContext(LocalesContext);
  const languageSwitchQuery = LanguageSwitchDocument;

  const languageSwitchQueryVariables: LanguageSwitchQueryVariables = {
    path: pathname,
    culture: localeCode,
  };

  const query = useSuspenseQuery(languageSwitchQuery, {
    variables: languageSwitchQueryVariables,
  }) as LanguageSwitchQueryResult;

  const languageSwitch = query?.data?.languageSwitch;

  const showSelect =
    locales &&
    languageSwitch &&
    languageSwitch.redirectUrlEn &&
    languageSwitch.redirectUrlSv;

  return (
    <>
      {props.useLabel && (
        <Text
          as="label"
          htmlFor={props.id || "language-switch-header"}
          fontSize={2}
          mb={0}
          mr={1}
        >
          {locales.label}
        </Text>
      )}
      {showSelect && (
        <Select
          fontSize={2}
          lineHeight={3}
          label={!props.useLabel ? locales.label : undefined}
          id={props.id || "language-switch-header"}
          options={
            [
              {
                value:
                  localeCode === "en-gb"
                    ? languageSwitch.redirectUrlEn
                    : languageSwitch.redirectUrlSv,
                label: props.useShortTitle
                  ? locales.primaryShortTitle
                  : locales.primaryTitle,
              },
              {
                value:
                  localeCode === "en-gb"
                    ? languageSwitch?.redirectUrlSv
                    : languageSwitch?.redirectUrlEn,
                label: props.useShortTitle
                  ? locales.secondaryShortTitle
                  : locales.secondaryTitle,
              },
            ] || []
          }
          onChange={(value) => {
            const url = value.currentTarget.value;
            window.location.href = url;
          }}
        />
      )}
    </>
  );
};

export { LanguageSwitch };
